import axios from 'axios';

// const apiUrl =
//   process.env.REACT_APP_API_URL ||
//   'http://active-group-backend.railway.internal/api';

// export const api = axios.create({
//   baseURL: '/api',
// });
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api',
});
